<template>
  <div class="contactUs">
    <div class="content_area">
      <div class="title"><span>//</span>联系我们</div>
      <div class="main">
        <div>
          大连市物流协会成立于2004年，大连市民政局注册登记，(登记证号0436)由全市港航物流企业、制造业企业、商贸流通企业、IT企业、科研教学等其他社会组织自愿组成的地方性、行业性、非赢利性的社会组织。目前拥有会员单位180家。业务指导单位为大连市港口与口岸局。会长由大连港集团总经理徐颂担任。
        </div>
        <div>
          协会成立以来，在市委、市政府的领导下，在广大会员单位及社会各界的支持下，按照“新体制、新产业、新机制”的建会方针，秉承“发现机会、整合资源、增值服务、引领发展”服务理念，紧密结合大连现代物流业发展的实际，团结和带领全市广大物流企业和物流创业者们，为推动大连国际航运中心建设和现代物流业发展做出积极贡献，树立了行业地位。一是围绕工作大局，落实各项决策，根据发展需求，发挥智力优势，推动物流理论、物流理念、物流成果深入和普及，用先进思想指导和引领大连物流业发展。二是开展典型宣传，培育重点企业，推出先进案例，用看得见摸得着的典型经验，促进大连现代物流业水平整体提高。三是紧密联系实际，创新活动载体，丰富文化生活，用生动活泼富有创新意识的各类活动，全面活跃和繁荣大连物流业。四是拓展业务领域，广交各界朋友，搭建市场平台，寻求商业机会，促进跨地区物流领域的广泛合作交流。五是加快人才培养，结合实战需求，担负大连物流从业人员的技术技能提升重要任务。六是维护企业合法权益，推动行业管理和行业自律不断深入，积极倡导建立公平、公正、有序的市场环境。七是加强自身建设，规范运营管理，探索市场经济体制下的社会组织运营模式，提高服务水平和服务能力，力求成为凝聚行业力量，形成行业优势的领跑者。
        </div>
        <div>
          大连市物流协会作为我市物流业的社会组织，为市委、市政府等有关部门提供了许多有价值的决策信息和数据支持，真正发挥了行业协会的桥梁纽带和参谋助手作用，在社会中赢得了信赖和好评。
        </div>
      </div>
      <div class="add_form_box">
        <div class="adress">
          <mapPage :longitude="longitude" :dimension="dimension"></mapPage>
        </div>
        <div class="formbox">
          <div class="ticps">
            如果您在使用本网站时有任何意见或建议，请写下您的意见或者建议。我们会尽快答复您，感谢您的关注~
          </div>
          <div class="name_tel">
            <el-input
              size="small"
              class="input"
              v-model="evaluateVal.name"
              placeholder="名字"
              style="width: 180px"
            />
            <el-input
              size="small"
              class="input1"
              type="number"
              v-model="evaluateVal.phone"
              placeholder="电话"
              style="width: 242px"
            />
          </div>
          <div class="email">
            <el-input
              size="small"
              class="input1"
              v-model="evaluateVal.email"
              placeholder="邮箱"
            />
          </div>
          <div>
            <el-input
              type="textarea"
              class="textarea"
              placeholder="请写下您想说的话"
              v-model="evaluateVal.content"
            />
          </div>
          <div class="btn">
            <el-button
              size="mini"
              type="primary"
              class="submit"
              @click="submit"
              :loading="btnLoading"
              >提交</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validateTelephone, validateEmail } from "~/baseUtils/validate";
import mapPage from "~/baseComponents/map";
export default {
  components: { mapPage },
  metaInfo: {
    title: "联系我们",
  },
  data() {
    return {
      evaluateVal: {
        name: "",
        email: "",
        phone: "",
        content: "",
      },
      longitude: 121.654154,
      dimension: 38.92831,
      btnLoading: false,
      evaluateRules: {
        phone: {
          validator: async (rule, value, callback) => {
            if (!validateTelephone(value)) {
              this.codeBtnDisabled = true;
              callback("手机号格式错误");
            } else {
            }
          },
          trigger: "blur",
        },
        email: {
          validator: async (rule, value, callback) => {
            if (!validateEmail(value)) {
              this.codeBtnDisabled = true;
              callback("邮箱格式错误");
            } else {
            }
          },
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    async submit() {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        return;
      }
      if (!this.evaluateVal.content) {
        this.$message.warning("输入建议不能为空");
        return;
      }
      this.btnLoading = true;
      this.evaluateVal.source = this.PJSource;
      let res = await this.$store.dispatch(
        "API_index/suggestionSave",
        this.evaluateVal
      );
      this.btnLoading = false;
      if (res.success) {
        this.$message.success("提交成功");
        this.evaluateVal = {};
      } else {
      }
    },
  },
  mounted() {
    console.log("this.PJSource", this.PJSource);
  },
};
</script>
<style lang="less" scoped>
.contactUs {
  background: #ffffff;
  .content_area {
    padding: 30px 0 50px;
    .title {
      font-size: 24px;
      span {
        color: #409eff;
        margin-right: 10px;
      }
    }
    .main {
      margin: 24px 0;
      div {
        text-indent: 2em;
        line-height: 25px;
      }
    }
    .add_form_box {
      display: flex;
    }
    .adress {
      height: 440px;
      width: 699px;
    }
    .formbox {
      background: #192f59;
      width: 502px;
      padding: 36px 32px 28px 32px;
      .ticps {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }
      .name_tel {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
      }
      .email {
        margin: 16px 0;
      }
      .btn {
        margin-top: 16px;
        text-align: center;
        button {
          width: 115px;
          height: 30px;
        }
      }
    }
  }

  /deep/.el-textarea__inner {
    min-height: 154px !important;
  }
  /deep/.el-form-item__error {
    color: #d8563a;
    margin-left: 16px;
  }
}
</style>